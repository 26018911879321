

















































































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import { View } from 'client-website-ts-library/plugins'
import { ListingFilter } from 'client-website-ts-library/filters'
import { ListingStatus, WebsiteLevel } from 'client-website-ts-library/types'
import { Config } from 'client-website-ts-library/services'
import Listings from '@/components/Listings.vue'
import ListingSearch from '@/components/ListingSearch.vue'
import ContentContainer from '@/components/Layout/ContentContainer.vue'
import ContentRow from '@/components/Layout/ContentRow.vue'
import SideBySideSection from '@/components/SideBySideSection.vue'
import ContactForm from '@/components/ContactForm.vue'

@Component({
  components: {
    Listings,
    ListingSearch,
    SideBySideSection,
    ContentContainer,
    ContentRow,
    ContactForm,
  },
})
export default class Resources extends Mixins(View) {
  private filter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Sold,
    ],
    SearchLevel: WebsiteLevel.Office,
    SearchGuid: Config.Website.Settings!.WebsiteId,
    PageSize: 6,
  });

  public isOpenNegotiation = false;

  public isOpenPrice = false;

  public isOpenMarketing = false;

  public isOpenInspections = false;

  toggleNegotiation() {
    this.isOpenNegotiation = !this.isOpenNegotiation
  }

  togglePrice() {
    this.isOpenPrice = !this.isOpenPrice
  }

  toggleMarketing() {
    this.isOpenMarketing = !this.isOpenMarketing
  }

  toggleInspections() {
    this.isOpenInspections = !this.isOpenInspections
  }
}
